import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import "yup-phone-lite";
import { StyledAsterisk, StyledFieldContainer, StyledFieldError, StyledFooter, StyledMessageError, StyledMessageSuccess, StyledNote, StyledSubmitButton, StyledText } from './activedemand-form.styles';

export const ActivedemandForm = ({ formId, text, fields, note, success, error }) => {

  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const schema = {};
  fields.map(field => {
    schema[field['key']] = Yup.string();

    if (field['type'] === "unicode" && field['label'].toLowerCase().indexOf('email') >= 0) {
      schema[field['key']] = schema[field['key']].email('Please enter a valid email address')
    }

    if (field['type'] === "unicode" && field['label'].toLowerCase().indexOf('phone') >= 0) {
      schema[field['key']] = schema[field['key']].phone("GB", `Please enter a valid phone number`)
    }

    if (field['required'] === true) {
      schema[field['key']] = schema[field['key']].required(field['choices'] ? `Please select one` : `Please enter your ${field['label'].toLowerCase()}`)
    }
  })

  const validation = Yup.object().shape(schema);

  if (isSuccess) {
    return (
      <StyledMessageSuccess dangerouslySetInnerHTML={{ __html: success }} />
    )
  }

  return (
    <>
      {text && <StyledText dangerouslySetInnerHTML={{ __html: text }} />}

      <Formik
        initialValues={fields.reduce((a, v) => ({ ...a, [v['key']]: v.type === "radio" ? v.choices[0] : '' }), {})}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          const body = {};

          fields.forEach(field => {
            if (field.type === "bool" && field.value) {
              if (values[field.key] === true) {
                body[field.key] = field.value;
              }
              else {
                body[field.key] = "";
              }
            }
            else {
              body[field.key] = values[field.key];
            }
          })

          fetch('/.netlify/functions/form', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              ...body,
              url: window.location.href,
              formId: formId
            })
          })
            .then(response => {
              if (response.ok) {
                setIsSuccess(true);
                (window.dataLayer || []).push({ event: 'submit_form_success' });
              }
              else {
                setIsError(true);
              }
              setSubmitting(false);
            })
        }}
      >
        {({ errors, touched, isSubmitting, isValid, dirty, values }) => (
          <Form>
            {fields.map((field, index) => {

              if (field.type === "radio") {
                return (
                  <StyledFieldContainer key={index} $fieldType={field.type} $set={!!values[field.key]} role="group">
                    {field.choices.map((choice, index) => (<>
                      <Field key={choice} id={`field.key-${index}`} type="radio" name={field.key} value={choice} checked={values[field.key] === choice} />
                      <label htmlFor={`field.key-${index}`}>{choice}</label>
                    </>
                    ))}
                  </StyledFieldContainer>
                )
              }

              const as = field.type === "textarea" ? "textarea" : field.type !== "bool" && (field.choices && field.choices.length > 0) ? "select" : "input";
              const type = as === "input" ? (field.type === "bool" ? "checkbox" : "text") : null;

              return (
                <StyledFieldContainer key={index} $fieldType={type} $set={!!values[field.key]}>
                  <label htmlFor={field.key}>{field.label}</label>
                  <Field id={field.key} type={type} name={field.key} placeholder={field.label} as={as}>
                    {as === "select" ? (
                      <>
                        <option disabled value="">{field.label}</option>
                        {field.choices.map((choice) => (<option key={choice} value={choice}>{choice}</option>))}
                      </>
                    ) : null}
                  </Field>
                  {field.required === true &&
                    <StyledAsterisk width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.02349 5.78826L4.7641 4.29905L8.02349 2.78174L7.26484 1.48921L4.37071 3.5123L4.65171 0H3.1906L3.47157 3.5123L0.57744 1.48921L-0.181213 2.78174L3.07818 4.29905L-0.181213 5.78826L0.57744 7.10888L3.47157 5.0577L3.1906 8.57H4.65171L4.37071 5.0577L7.26484 7.10888L8.02349 5.78826Z" fill="#938B8B" />
                    </StyledAsterisk>
                  }
                  {errors[field.key] && touched[field.key] ? (<StyledFieldError>{errors[field.key] as string}</StyledFieldError>) : null}
                </StyledFieldContainer>
              )
            })}

            <StyledNote dangerouslySetInnerHTML={{ __html: note }} />

            <StyledFooter>
              <StyledSubmitButton type="submit" disabled={isSubmitting || !isValid || !dirty}>Submit</StyledSubmitButton>
              {isError && <StyledMessageError dangerouslySetInnerHTML={{ __html: error }} />}
            </StyledFooter>
          </Form>
        )}
      </Formik>
    </>
  )
}