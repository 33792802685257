import React from 'react';

const ModalFormStateContext = React.createContext();
const ModalFormDispatchContext = React.createContext();

const modalFormReducer = (state, { action, id }) => {
	switch (action.type) {
		case 'open': {
			return { ...state, open: true, id };
		}

		case 'close': {
			return { ...state, open: false, id };
		}

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
};

const ModalFormProvider = ({ children }) => {
	const [state, dispatch] = React.useReducer(modalFormReducer, {
		open: false,
		id: null
	});
	return (
		<ModalFormStateContext.Provider value={state}>
			<ModalFormDispatchContext.Provider value={dispatch}>
				{children}
			</ModalFormDispatchContext.Provider>
		</ModalFormStateContext.Provider>
	);
};

const useModalFormState = () => {
	const context = React.useContext(ModalFormStateContext);
	if (context === undefined) {
		throw new Error('useModalFormState must be used within a ModalFormProvider');
	}
	return context;
};

const useModalFormDispatch = () => {
	const context = React.useContext(ModalFormDispatchContext);
	if (context === undefined) {
		throw new Error('useModalFormDispatch must be used within a ModalFormProvider');
	}
	return context;
};

export { ModalFormProvider, useModalFormDispatch, useModalFormState };

