import Color from "color";
import styled from "styled-components";
import { RED, WHITE } from "../../../theme/colors";
import { SIZE, mediaQuery, unit } from "../../../theme/metrics";
import { typeBodyMd, typeBodySm, typeInput, typeLabelMd } from "../../../theme/typography";

export const StyledFieldContainer = styled.div<{ $fieldType: string | null, $set: boolean }>`
	position: relative;

  input, textarea, select{
    color: ${({ theme }) => theme.primary};
		border: 1px solid ${({ theme }) => Color(theme.primary).alpha(0.3).string()};
		${typeBodySm}

		&::placeholder{
			color: ${({ theme }) => Color(theme.primary).alpha(0.3).string()};
		}

		&:hover, &:focus{
			border: 1px solid ${({ theme }) => theme.primary};
		}

		&[type="checkbox"], &[type="radio"]{
			width: ${unit(3)};
			height: ${unit(3)};
			margin: 0;
			align-self: flex-start;
			flex: 0 0 ${unit(3)};
			accent-color: ${({ theme }) => theme.secondary};
		}
  }

  select{
    color: ${({ theme, $set }) => $set ? theme.primary : Color(theme.primary).alpha(0.3).string()};
  }

	${({ $fieldType }) => $fieldType === "checkbox" ? `
		display: flex;
		flex-direction: row-reverse;
    gap: ${unit(2)};

		input{
			width: ${unit(3)};
			height: ${unit(3)};
			border: 1px solid ${({ theme }) => Color(theme.primary).alpha(0.3).string()};
			background-color: transparent;
			flex: 0 0 ${unit(3)};
		}

		label{
			flex: 1;
		}

	` : ``}

	${({ $fieldType, theme }) => $fieldType === "radio" && `

		display: flex;
		gap: ${unit(2)};

		input{
			display: none;
		}
		
		label{
			${typeBodySm}
			background-color: transparent;
			color: ${theme.primary};
			border: 1px solid ${theme.primary};
			padding: ${unit(2)};
			flex: 1;
			text-align: center;
			cursor: pointer;
		}

		input[type="radio"]:checked + label {
      background-color: ${theme.secondary};
			color: ${theme.primary};
			border: none;
    }
	`}

	&:not(:first-child) {
		margin-top: ${unit(2)};
	}

	label{
		display: ${({ $fieldType }) => $fieldType === "checkbox" || $fieldType === "radio" ? "block" : "none"};
    color: ${({ theme }) => theme.primary};
		${typeBodySm}
    user-select: none;
		
	}
`;

export const StyledAsterisk = styled.svg`
	position: absolute;
	top: ${unit(1)};
	right: ${unit(1)};
`;

export const StyledTextarea = styled.textarea`
	margin-top: ${unit(3)};
	margin-bottom: ${unit(4.5)};
`;

export const StyledNote = styled.div`
	${typeBodySm};
	color: ${({ theme }) => Color(theme.primary).alpha(0.6).string()};
	margin-top: ${unit(5)};

	a{
		color: ${({ theme }) => Color(theme.primary).alpha(0.6).string()};
	}
`;

export const StyledSubmitButton = styled.button`
	${typeLabelMd};
	padding: ${unit(2)};
  background-color: ${({ theme }) => theme.secondary};
	color: ${({ theme }) => theme.primary};

	&:disabled{
		opacity: 0.5;
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.background};
    cursor: default;
	}
`;

export const StyledText = styled.div`
	${typeBodyMd}
	color: ${({ theme }) => theme.primary};
`

export const StyledMessageError = styled.div`
	${typeBodySm};
	color: ${({ theme }) => theme.primary};
`;

export const StyledMessageSuccess = styled.div`
	${typeBodyMd};
	color: ${({ theme }) => theme.primary};
`;

export const StyledFieldError = styled.div`
	${typeBodySm};
	width: 100%;
	padding-top: ${unit(1.5)};
	padding-bottom: ${unit(1.5)};
	padding-left: ${unit(3)};
	padding-right: ${unit(3)};
	background-color: ${RED};
  color: ${WHITE};
	text-align: center;
`;

export const StyledSelectWrapper = styled.div`
	position: relative;
	width: 100%;
	margin-top: ${unit(3)};
	border-radius: 0;
`;

export const StyledInputSelect = styled.select`
	${typeInput};
	width: 100%;
	height: ${unit(7)};
	padding-left: ${unit(3)};
	background-color: transparent;
	color: ${({ theme }) => theme.primary};
	appearance: none;
	outline: none;
	cursor: pointer;
	border: 1px solid ${({ theme }) => Color(theme.primary).alpha(0.3).string()};
	transition: border-color 380ms cubic-bezier(0.22, 1, 0.36, 1);

	option{
		color: initial;
	}

	${mediaQuery[SIZE.large]} {
		&:hover {
			border-color: ${({ theme }) => theme.primary};
		}

		&:focus {
			border-color: ${props => props.theme.secondary};
		}
	}
`;

export const StyledInputOption = styled.option`
	color: #000;

	&:disabled {
		color: gray;
	}
`;

export const StyledFooter = styled.div`
  display: flex;
  margin-top: ${unit(5)};
  gap: ${unit(2)};
  align-items: center;
`;