import Color from 'color';
import styled from 'styled-components';

import { GREIGE, YELLOW } from '../../../theme/colors';
import { MAX_WIDTH, SIZE, gutter, margin, mediaQuery, pixelate, unit } from '../../../theme/metrics';
import { typeBodyMd, typeBodyXs, typeLabelLg } from '../../../theme/typography';


export const StyledContainer = styled.div`
  
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.primary};
	padding-top: ${unit(10)};
	padding-bottom: ${unit(3)};
	border-bottom: 3px solid ${({ theme }) => theme.secondary};
  
	${mediaQuery[SIZE.large]} {
		padding-top: ${unit(15)};
	}
`;

export const StyledContent = styled.div`
  max-width: ${pixelate(MAX_WIDTH)};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  padding-left: ${margin(SIZE.small)};
	padding-right: ${margin(SIZE.small)};
  column-gap: ${gutter(SIZE.small)};
  grid-auto-flow: dense;
  grid-template-rows: auto;
  grid-template-columns: repeat(12, [col-start] 1fr);
	row-gap: ${unit(5)};
  
  ${mediaQuery[SIZE.medium]} {
    padding-left: ${margin(SIZE.medium)};
    padding-right: ${margin(SIZE.medium)};
    column-gap: ${gutter(SIZE.medium)};
  }

  ${mediaQuery[SIZE.large]} {
    padding-left: ${margin(SIZE.large)};
    padding-right: ${margin(SIZE.large)};
    column-gap: ${gutter(SIZE.large)};
		row-gap: ${unit(10)};
  }

  ${mediaQuery[SIZE.xlarge]} {
    padding-left: ${margin(SIZE.xlarge)};
    padding-right: ${margin(SIZE.xlarge)};
    column-gap: ${gutter(SIZE.xlarge)};
  }

  ${mediaQuery[SIZE.max]} {
    padding-left: ${margin(SIZE.max)};
    padding-right: ${margin(SIZE.max)};
    column-gap: ${gutter(SIZE.max)};
  }
`;

export const StyledAddressText = styled.address`
	${typeBodyMd};
	color: ${({ theme }) => Color(theme.primary).alpha(0.7).string()};

	a{
		color: ${({ theme }) => Color(theme.primary).alpha(0.7).string()};
	}
`;

export const StyledGroup = styled.div`
	grid-column: span 12;
	display: flex;
	flex-direction: column;
	row-gap: ${unit(2)};

	h2{
		${typeLabelLg}
		color: ${({ theme }) => theme.primary};
	}

	${mediaQuery[SIZE.medium]} {
		grid-column: span 6;
	}

	${mediaQuery[SIZE.large]} {
		grid-column: span 3;
	}
`;

export const StyledLink = styled.span`
	color: ${({ theme }) => Color(theme.primary).alpha(0.7).string()};
	text-decoration: none;
	position: relative;
	
	&:after {
		position: absolute;
		content: '';
		left: 0;
		bottom: -2px;
		width: 100%;
		height: 1px;
		background-color: ${YELLOW};
		transform: scale(0);
		transition: transform 280ms ease-out;
	}

	${mediaQuery[SIZE.large]} {
		&:hover{
			&:after {
				transform: scale(1);
			}
		}
	}
`;

export const StyledSocial = styled.div`
	grid-column: span 12;
	display: flex;
	flex-direction: column;
	row-gap: ${unit(2)};

	h2{
		${typeLabelLg}
		color: ${({ theme }) => theme.primary};
	}

	>div{
		display: flex;
		align-items: center;
		column-gap: ${unit(3)};

		a {
			transition: opacity 180ms ease-out;
			padding: 4px 0;

			svg{
				display: block;
			}

			${mediaQuery[SIZE.large]} {
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}

	${mediaQuery[SIZE.large]} {
		grid-column: 10 / span 3;
	}
`;

export const StyledBottom = styled.div`
	grid-column: span 12;
`;

export const StyledCopyright = styled.div`
	${typeBodyXs};
	color: ${GREIGE};
	text-transform: uppercase;
	margin-right: ${unit(4)};
`;

export const StyledLegal = styled.div`
	display: flex;
	margin-bottom: ${unit(5)};
	flex-direction: column;
	row-gap: ${unit(2)};

	${mediaQuery[SIZE.large]} {
		flex-direction: row;
		column-gap: ${unit(1)};
		margin-bottom: ${unit(2)};
	}
`;

export const StyledCompanyText = styled.div`
	${typeBodyXs};
	color: ${GREIGE};
	line-height: 1.5;
`;

export const StyledLegalLinks = styled.ul`
	${typeBodyXs};
	color: ${GREIGE};
	text-transform: uppercase;
	display: flex;
	flex-direction: column;
	row-gap: ${unit(2)};

	li{
		a{
			color: ${GREIGE};
			text-decoration: none;
			transition: opacity 180ms ease-out;
	
			${mediaQuery[SIZE.large]} {
				&:hover {
					opacity: 0.8;
				}
			}
			
		}
	}

	${mediaQuery[SIZE.medium]} {
		flex-direction: row;
		column-gap: ${unit(1)};

		li{
			&:after{
				content: '|';
				margin-left: ${unit(1)};
			}

			&:last-child{
				&:after{
					content: none;
				}
			}
		}
	}
`;

export const StyledCertification = styled.div`
	display: flex;
	row-gap: ${unit(5)};
	flex-direction: column;
	grid-column: span 12;

	>div{
		${typeBodyXs};
		color: ${GREIGE};
		line-height: 1.5;
		max-width: 680px;

		a{
			color: ${GREIGE};
			text-decoration: underline;
			transition: opacity 180ms ease-out;

			&:after{
				content: none;
			}

			${mediaQuery[SIZE.large]} {
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}

	${mediaQuery[SIZE.large]} {
		grid-column: span 9;
	}
`;

export const StyledLogos = styled.ul`
	display: flex;
	flex-direction: column-reverse;
	gap: ${unit(4)};

	${mediaQuery[SIZE.medium]} {
		flex-direction: row;
		gap: ${unit(6)};
		align-items: center;
	}
`