import Color from 'color';
import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { WHITE } from './colors';
import { unit } from './metrics';
import { typeBodyMd, typeInput } from './typography';

export const GlobalStyle = createGlobalStyle`
	${reset}

	html{
		-webkit-font-smoothing: antialiased;
		text-rendering: optimizeLegibility;
	}

	html {
		box-sizing: border-box;
		background-color: ${props => props.theme.background};
	}

	html, body{
		${typeBodyMd};
		color: ${props => props.theme.primary};

		a {
			color: ${props => props.theme.primary};
		}
	}

	html.lenis, html.lenis body {
		height: auto;
	}

	.lenis.lenis-smooth {
		scroll-behavior: auto !important;
	}

	.lenis.lenis-smooth [data-lenis-prevent] {
		overscroll-behavior: contain;
	}

	.lenis.lenis-stopped {
		overflow: hidden;
	}

	.lenis.lenis-smooth iframe {
		pointer-events: none;
	}

	*, *:before, *:after {
		box-sizing: inherit;
	}

	a{ 
		-webkit-tap-highlight-color: rgba(0,0,0,0);
	}

	button{
		outline: none;
		border: none;
		background: transparent;
	}

	input, textarea {
		${typeInput};
		width: 100%;
		/* push the text down a bit to achieve vertical align */
		padding-top: 2px;
		padding-left: ${unit(3)};
		color: ${WHITE};
		background-color: transparent;
		border: 1px solid ${Color(WHITE).alpha(0.3).string()};
		border-radius: 0;
		outline: none;
		transition: border-color 250ms ease-out;

		&::-webkit-input-placeholder {
			color: ${WHITE};
		}

		&:-ms-input-placeholder {
			color: ${WHITE};
		}

		&:-moz-input-placeholder {
			color: ${WHITE};
		}

		&[type="checkbox"]{
			filter: grayscale(100%);
		}

		&::placeholder {
			color: ${WHITE};
		}

		&:focus, &:hover {
			border-color: ${props => props.theme.secondary};
		}
	}

	select {
		border-radius: 0;
		${typeInput};
		height: ${unit(7)};
		padding-top: 2px;
		padding-left: ${unit(3)};
		padding-right: ${unit(3)};
		width: 100%;
		background-color: transparent;
		color: ${WHITE};
		appearance: none;
		background-image:
			linear-gradient(45deg, transparent 50%, gray 50%),
			linear-gradient(135deg, gray 50%, transparent 50%);
		background-position:
			calc(100% - ${unit(3)} - 5px) 50%,
			calc(100% - ${unit(3)}) 50%;
		background-size:
			5px 5px,
			5px 5px;
		background-repeat: no-repeat;

		option{
			color: initial;
		}

		&:focus, &:hover {
			border-color: ${props => props.theme.secondary};
			outline: none;
		}
	}

	input {
		height: ${unit(7)};

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&[type=number] {
			-moz-appearance:textfield;
		}
	}

	textarea {
		height: ${unit(22.5)};
		padding-top: ${unit(2)};
		resize: none;
	}

	button {
		margin: 0;
		padding: 0;
		border: none;
		outline: none;
		cursor: pointer;
	}
`;