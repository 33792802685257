import { css } from 'styled-components';

import { mediaQuery, SIZE } from './metrics';


export const typeBodyInitial = css`
	font-family: "Gotham";
	font-style: normal;
	font-weight: 300;
	font-size: 91px;
	line-height: 65px;
`;


// -----------

export const typeHeadingLg = css`
	font-family: "Archer";
	font-style: normal;
	font-weight: 300;
	font-size: 50px;
	line-height: 1.2;

	${mediaQuery[SIZE.large]} {
		font-size: 60px;
	}
`;

export const typeHeadingMd = css`
	font-family: "Archer";
	font-style: normal;
	font-weight: 300;
	font-size: 36px;
	line-height: 1.2;

	${mediaQuery[SIZE.large]} {
		font-size: 42px;
	}
`;

export const typeHeadingSm = css`
	font-family: "Archer";
	font-style: normal;
	font-weight: 300;
	font-size: 25px;
	line-height: 1.2;
`;

export const typeHeadingXs = css`
	font-family: "Archer";
	font-style: normal;
	font-weight: 300;
	font-size: 19x;
	line-height: 1.2;
`;

export const typeLabelMd = css`
	font-family: "Gotham";
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	text-transform: uppercase;
`;

export const typeLabelLg = css`
	font-family: "Gotham";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 14px;
	text-transform: uppercase;
`;

export const typeBodyMd = css`
	font-family: "Gotham";
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 1.625;
`;

export const typeBodySm = css`
	font-family: "Gotham";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 1.3;
`;

export const typeBodyXs = css`
	font-family: "Gotham";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
`;

export const typeInput = css`
	font-family: "Gotham";
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 1.625;
`;