import { useLocation } from '@reach/router';
import { ReactLenis } from 'lenis/react';
import React from 'react';
import { ThemeProvider } from "styled-components";

import { GlobalStyle } from '../../../theme/global';
import { THEME_DEFAULT } from '../../../theme/theme';

import { useVideoState } from '../../../context/video-context';

import PageError from '../../page-error';

import { Container, Wrapper } from './layout.styles';

import get from 'lodash/get';
import Footer from '../footer';

import loadable from '@loadable/component';
import { useModalFormState } from '../../../context/modal-form-context';
import { Header } from '../header';
import ModalForm from '../modal-form/modal-form.component';
const VideoOverlay = loadable(() => import('../video-overlay'))

const ErrorScreen = () =>
	<Container>
		<PageError message="Something went wrong" />
	</Container>

const Layout = ({ children }) => {

	const { open: videoOpen } = useVideoState();
	const { open: modalFormOpen } = useModalFormState();

	const location = useLocation();

	let darkHeader = false;
	if (children.props.data.page && (children.props.data.page.image || children.props.data.page.backgroundVideo)) {
		darkHeader = true;
	}

	if (children.props.location.pathname.includes('contact')
		|| children.props.location.pathname.includes('legal')
		|| children.props.location.pathname.includes('article')) {
		darkHeader = true;
	}

	const isLandingPage = get(children, 'props.data.page.model.apiKey') === 'landing';
	const isConfirmationPage = get(children, 'props.data.page.model.apiKey') === 'confirmation';
	const isCareersPage = location.pathname.includes('/careers');

	return (
		<ReactLenis root>
			<ThemeProvider theme={THEME_DEFAULT}>
				<Wrapper>
					<GlobalStyle />
					{/* <ErrorBoundary FallbackComponent={ErrorScreen}> */}
					{videoOpen && <VideoOverlay />}
					{modalFormOpen && <ModalForm />}
					<Header dark={darkHeader} isCompact={isLandingPage} />
					<Container>{children}</Container>
					<Footer hideForm={isLandingPage || isCareersPage || isConfirmationPage} isCompact={isLandingPage} />
					{/* </ErrorBoundary> */}
				</Wrapper>
			</ThemeProvider>
		</ReactLenis>
	)
}

export default Layout;