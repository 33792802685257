import Color from 'color';
import styled from 'styled-components';

import { BLACK, PAPER } from '../../../theme/colors';
import { MAX_WIDTH, SIZE, ZINDEX_NAVBAR, mediaQuery, pixelate, unit } from '../../../theme/metrics';

import { GatsbyImage } from 'gatsby-plugin-image';
import { DARK_GRAY, GREIGE, YELLOW } from '../../../theme/colors';
import { typeBodyMd, typeBodySm, typeHeadingMd, typeLabelMd } from '../../../theme/typography';

export const StyledContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: ${unit(2)};
	width: 100vw;
	height: 100vh;
	z-index: ${ZINDEX_NAVBAR + 1};

	${mediaQuery[SIZE.large]} {
		justify-content: center;
	}
`;

export const CloseIcon = styled.svg`
	position: absolute;
	top: ${unit(2)};
	right: ${unit(2)};
	cursor: pointer;
	stroke: ${BLACK};
`;

export const StyledBackground = styled.div`
	background-color: ${Color(BLACK).alpha(0.9).string()};
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`

export const StyledModal = styled.div`
	position: relative;
	width: 100%;
	height: calc(100vh - ${unit(4)});
	background-color: ${PAPER};
	display: flex;
	width: calc(100vw - ${unit(4)});
	max-width: ${pixelate(MAX_WIDTH)};

	${mediaQuery[SIZE.large]} {
		height: calc(100vh - ${unit(32.5)});
	}
`;

export const StyledImageContainer = styled.div`
	display: none;

	${mediaQuery[SIZE.large]} {
		flex: 0 0 50%;
		display: block;
	}
`;

export const StyledContentContainer = styled.div`
	overflow: scroll;
	padding: ${unit(4)};
	display: flex;
	flex-direction: column;
	gap: ${unit(3)};

	${mediaQuery[SIZE.large]} {
		flex: 0 0 50%;
		padding: ${unit(10)};
	}
`;

export const StyledImage = styled(GatsbyImage)`
	height: 100%;
`

export const StyledHeading = styled.p`
	${typeHeadingMd}
	color: ${DARK_GRAY};
`

export const StyledText = styled.p`
	${typeBodyMd}
	color: ${DARK_GRAY};
`

export const StyledActiveDemandForm = styled.div`
	label{
		${typeBodySm}
		color: ${DARK_GRAY};
		line-height: ${unit(3)};
		display: flex;
		align-items: center;
		gap: ${unit(2)};
	}

	input, textarea, select{
		color: ${DARK_GRAY};
		border: 1px solid ${Color(DARK_GRAY).alpha(0.3).string()};
		${typeBodySm}

		&::placeholder{
			color: ${Color(DARK_GRAY).alpha(0.3).string()};
		}

		&:hover, &:focus{
			border: 1px solid ${DARK_GRAY};
		}

		&[type="checkbox"], &[type="radio"]{
			width: ${unit(3)};
			height: ${unit(3)};
			margin: 0;
			align-self: flex-start;
			flex: 0 0 ${unit(3)};
			accent-color: ${YELLOW};
		}
	}

	.radio{
		margin-bottom: ${unit(1)};

		&:last-child{
			margin-bottom: 0;
		}
	}

	button{
		${typeLabelMd}
		background-color: ${YELLOW};
		color: ${DARK_GRAY};
		padding: ${unit(2)};
	}

	.cta_button{
		margin-top: ${unit(5)};
	}

	.cta_text{
		color: ${GREIGE};
	}

	.ad-form-field{
		margin-bottom: ${unit(3)};
	}
`

export const StyledToggleContainer = styled.ul`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
	gap: ${unit(2)};
`

export const StyledToggleItem = styled.ul`
	display: flex;
	flex-direction: column;
`

export const StyledToggleButton = styled.button`
	${typeLabelMd}
	border: 1px solid ${({ $selected }) => $selected ? YELLOW : DARK_GRAY};
	background-color: ${({ $selected }) => $selected ? YELLOW : "transparent"};
	color: ${DARK_GRAY};
	padding: ${unit(2)};
`