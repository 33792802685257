exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-apartment-js": () => import("./../../../src/templates/apartment.js" /* webpackChunkName: "component---src-templates-apartment-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-confirmation-js": () => import("./../../../src/templates/confirmation.js" /* webpackChunkName: "component---src-templates-confirmation-js" */),
  "component---src-templates-generic-js": () => import("./../../../src/templates/generic.js" /* webpackChunkName: "component---src-templates-generic-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-property-js": () => import("./../../../src/templates/property.js" /* webpackChunkName: "component---src-templates-property-js" */)
}

