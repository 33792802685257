import styled, { keyframes } from "styled-components";

import { DARK_GRAY, WHITE, YELLOW } from '../../../theme/colors';
import { SIZE, mediaQuery, pixelate, unit } from '../../../theme/metrics';
import { typeLabelMd } from '../../../theme/typography';

const animationOver = keyframes`
	0% { transform: translateY(100%); }
	100% { transform: translateY(0); }
`;

const animationOut = keyframes`
	0% { transform: translateY(0); }
	100% { transform: translateY(-100%); }
`;

export const StyledLink = styled.span<{ $variant, $over, $out }>`
  color: ${({ $variant, theme }) => $variant === "primary" ? DARK_GRAY : $variant === "outline" || $variant === "invert" ? theme.primary : theme.background};
  background-color: ${({ $variant, theme }) => $variant === "primary" ? YELLOW : $variant === "outline" ? "transparent" : $variant === "invert" ? theme.background : theme.primary};
  border: ${({ $variant, theme }) => $variant === "primary" ? `none` : $variant === "outline" ? `2px solid ${theme.alternative}` : `none`};
  display: inline-block;
  ${typeLabelMd};
	position: relative;
	padding-top: ${({ $variant }) => $variant === "outline" ? pixelate(unit(2, { pixelate: false }) - 2) : unit(2)};
	padding-bottom: ${({ $variant }) => $variant === "outline" ? pixelate(unit(2, { pixelate: false }) - 2) : unit(2)};
	padding-left: ${({ $variant }) => $variant === "outline" ? pixelate(unit(3, { pixelate: false }) - 2) : unit(3)};
	padding-right: ${({ $variant }) => $variant === "outline" ? pixelate(unit(3, { pixelate: false }) - 2) : unit(3)};
	text-align: center;
	text-decoration: none;
	overflow: hidden;
	cursor: pointer;
  transition: color 0.2s;
  user-select: none;

  span{
    position: relative;
    z-index: 1;
  }

  svg{
    position: relative;
    z-index: 1;
    top: 3px;
	  margin-left: ${unit(1)};
    stroke: ${({ $variant, theme }) => $variant === "primary" ? DARK_GRAY : $variant === "outline" || $variant === "invert" ? theme.primary : theme.background};
  }

  ${mediaQuery[SIZE.medium]} {
  
    &:after{
      content: "";
      background-color: ${({ $variant, theme }) => $variant === "primary" ? WHITE : $variant === "outline" ? theme.primary : YELLOW};
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateY(-100%);
      animation-duration: 480ms;
      animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
      animation-fill-mode: forwards;
      animation-name: ${({ $over, $out }) => $over ? animationOver : $out ? animationOut : `none`};
    }

    &:hover{
      color: ${({ $variant, theme }) => $variant === "outline" ? theme.background : DARK_GRAY};

      svg{
        stroke: ${({ $variant, theme }) => $variant === "outline" ? theme.background : DARK_GRAY};
      }

      &:after{
        animation-name: ${animationOver};
      }
    }
  }

`

export const StyledList = styled.ul`
display: flex;
gap: ${unit(2)};
`

export const StyledListItem = styled.li`
  display: flex;
`