import { Link } from "gatsby";
import React, { useState } from "react";
import { getPathFromModel } from "../../../utils/link";
import { StyledLink, StyledList, StyledListItem } from "./button-link.styles";
import { ButtonLinkListProps, ButtonLinkProps } from "./button-link.types";

import { useLenis } from "lenis/react";
import { useModalFormDispatch } from '../../../context/modal-form-context';

export const ButtonLink = ({
  link,
  className,
  variant
}: ButtonLinkProps) => {

  let props;

  const modalFormDispatch = useModalFormDispatch();

  const [hover, setHover] = useState<boolean | null>(null);

  const lenis = useLenis();

  const scrollTo = (index) => {
    lenis?.scrollTo(`[data-scroll-index="${index}"]`, { offset: -96 })
  }

  if (link && link.model && link.model.apiKey) {
    if (link.model.apiKey === "link_page") {
      const url = getPathFromModel({ ...link.page })
      props = {
        as: Link,
        to: url
      }
    }
    else if (link.model.apiKey === "link_url") {
      props = {
        as: "a",
        target: "_blank",
        href: link.url
      }
    }
    else if (link.model.apiKey === "link_download") {
      props = {
        as: "a",
        target: "_blank",
        href: link.file.url
      }
    }
    else if (link.model.apiKey === "link_phone") {
      props = {
        as: "a",
        href: `tel:${link.phone}`
      }
    }
    else if (link.model.apiKey === "link_form") {
      props = {
        as: "span",
        onClick: () => {
          modalFormDispatch({ action: { type: 'open' }, ...link.form });
        }
      }
    }
    else if (link.model.apiKey === "link_scroll") {
      props = {
        as: "span",
        onClick: () => {
          scrollTo(link.index);
        }
      }
    }
  }


  if (props) {
    return (
      <StyledLink className={className} {...props} $variant={variant} $over={hover === true} $out={hover === false} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <span>{link.label}</span>
        {props.href && <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 12L12 4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4 4H12V12" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>}
      </StyledLink>
    )
  }

  return null;
};

export const ButtonLinkList = ({
  links,
  variant,
  className
}: ButtonLinkListProps) => {
  if (links && links.length > 0) {
    return (
      <StyledList className={className}>
        {links.map((item, index) => (
          <StyledListItem key={index}><ButtonLink variant={variant === "invert" && index === 0 ? "solid" : variant} link={item} /></StyledListItem>
        ))}
      </StyledList>
    )
  }

  return null;
}