import Color from "color";
import { Link } from "gatsby";
import styled from "styled-components";
import { DARK_GRAY, PAPER, WHITE, YELLOW } from "../../../theme/colors";
import { SIZE, ZINDEX_NAVBAR, mediaQuery, unit } from "../../../theme/metrics";
import { typeLabelMd } from "../../../theme/typography";

export const StyledBannerContainer = styled.div`
  background-color: ${DARK_GRAY};
  padding: 0 ${unit(4)};
  display: flex;
  flex-direction: column;

  ${mediaQuery[SIZE.large]} {
    height: 30px;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
  }
`;

export const StyledMenuContainer = styled.div`
  padding: 0 ${unit(4)};
  display: flex;
  position: relative;
  flex-direction: column;
  order: -1;
  flex: 1;
  border-top: 1px solid ${DARK_GRAY};
  overflow: auto;

  ${mediaQuery[SIZE.large]} {
    border-top: none;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 0 ${unit(4)};
    order: initial;
    overflow: initial;
    flex: 0 0 ${unit(12)};
  }
`;

export const StyledMenuLinks = styled.ul<{ $isCompact?: boolean }>`
  display: flex;
  flex-direction: column;
  ${typeLabelMd}
  column-gap: ${unit(4)};
  padding: ${unit(2)} 0;

  a, span{
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    display: block;
    user-select: none;
  }

  >li{
    position: relative;

    >a, >span{
      padding: ${unit(2)} 0;
    }

    >span{
      position: relative;

      &:before, &:after{
        content: "";
        background-color: ${DARK_GRAY};
        width: 20px;
        height: 1px;
        position: absolute;
        right: 0;
        top: 50%;
      }

      &:after{
        transform: rotate(90deg);
      }
    }

    &[data-open="true"]{
      >span{
        &:after{
          display: none;
        }
      }

      >ul{
        display: flex;
      }
    }

    >ul{
      display: none;
      flex-direction: column;

      >li{
        >a{
          color: ${DARK_GRAY};
          padding: ${unit(2)} ${unit(3)};
        }
      }
    }
  }

  ${mediaQuery[SIZE.large]} {
    flex-direction: row;
    padding: 0;
    display: ${({ $isCompact }) => $isCompact ? `none` : `flex`};

    >li{

      >ul{
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        background-color: ${PAPER};
        padding: ${unit(1.5)} ${unit(3)};
        display: none;
        flex-direction: column;

        >li{
          >a{
            color: ${DARK_GRAY};
            padding: ${unit(1.5)} 0;
            transition: color 0.5s;

            &:hover{
              color: ${Color(DARK_GRAY).alpha(0.7).string()};
            }
          }
        }
      }

      &[data-open="true"]{
        >ul{
          display: none;
        }
      }

      &[data-over="true"]{
        >ul{
          display: flex;
        }
      }

      >span{
        &:before, &:after{
          content: none;
        }
      }

      >a, >span{
        &:after{
          position: absolute;
          content: '';
          left: 0;
          top: 50%;
          width: 100%;
          height: 1px;
          background-color: ${YELLOW};
          transition: transform 280ms ease-out;
          transform: translateY(10px) scaleX(0);
        }

        &:hover{
          &:after{
            transform: translateY(10px) scaleX(1);
          }
        }
      }
    }
  }
`

export const StyledLogo = styled(Link)`
  position: absolute;
  top: 26px;
  left: ${unit(3)};
  z-index: 1;

  svg{
    height: 30px;
  }

  ${mediaQuery[SIZE.large]} {
    left: ${unit(4)};
    top: calc(50% + 15px);
    transform: translateY(-50%);

    svg{
      height: 45px;
    }
  }
`;

export const StyledBurger = styled.button`
  width: ${unit(5)};
  height: ${unit(5)};
  position: absolute;
  right: ${unit(3)};
  top: ${unit(2.5)};
  z-index: 1;
  cursor: pointer;
  background: transparent;
  
  &:before, &:after{
    content: "";
    height: 1px;
    width: 100%;
    left: 0;
    position: absolute;
    top: 50%;
    transition: transform 0.5s;
  }

  ${mediaQuery[SIZE.large]} {
    display: none;
  }
`

export const StyledHeaderContent = styled.div`
  width: 100%;
  background-color: ${PAPER};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: ${unit(10)};
  position: relative;

  ${mediaQuery[SIZE.large]} {
    position: static;
    background-color: transparent;
    padding-top: 0;
  }
`

export const StyledHeaderContainer = styled.div<{ $sticky: boolean, $open: boolean }>`
  position: fixed;
  top: 0;
  z-index: ${ZINDEX_NAVBAR};
  left: 0;
  width: 100%;
  height: ${({ $open }) => $open ? `100%` : unit(10)};
  background-color: ${({ $sticky }) => $sticky ? DARK_GRAY : `transparent`};
  transition: background-color 0.5s;
  display: flex;

  ${mediaQuery[SIZE.large]} {
    position: ${({ $sticky }) => $sticky ? `fixed` : `absolute`};
    top: ${({ $sticky }) => $sticky ? `-30px` : `0`};
    height: auto;
  }

  ${StyledHeaderContent}{
    visibility: ${({ $open }) => $open ? `visible` : `hidden`};

    ${mediaQuery[SIZE.large]} {
      visibility: visible;
    }
  }

  ${StyledMenuLinks}{
    >li{
      >a, >span{
        color: ${DARK_GRAY};
        transition: color 0.5s;
      }
    }
    ${mediaQuery[SIZE.large]} {
      >li{
        >a, >span{
          color: ${({ theme, $sticky }) => $sticky ? WHITE : theme.primary};
        }
      }
    }
  }

  ${StyledLogo}{
    svg{
      fill: ${({ theme, $sticky, $open }) => $open ? DARK_GRAY : $sticky ? WHITE : theme.primary};
      transition: fill 0.5s;
    }

    ${mediaQuery[SIZE.large]} {
      svg{
        fill: ${({ theme, $sticky }) => $sticky ? WHITE : theme.primary};
      }
    }
  }

  ${StyledBurger}{
    &:before, &:after{
      background-color: ${({ theme, $sticky, $open }) => $open ? DARK_GRAY : $sticky ? WHITE : theme.primary};
    }

    &:before{
      transform: ${({ $open }) => $open ? `rotate(-45deg)` : `translateY(-4px)`};
    }

    &:after{
      transform: ${({ $open }) => $open ? `rotate(45deg)` : `translateY(4px)`};
    }

    ${mediaQuery[SIZE.large]} {
      &:before, &:after{
        background-color: ${({ theme, $sticky }) => $sticky ? WHITE : theme.primary};
      }
    }
  }
`

export const StyledBannerLinks = styled.ul`
  display: flex;
  flex-direction: column;
  column-gap: ${unit(4)};
  ${typeLabelMd}
  padding: ${unit(2)} 0;

  a, span{
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    display: block;
    user-select: none;
  }

  >li{
    position: relative;

    >a, >span{
      color: ${WHITE};
      padding: ${unit(2)} 0;
    }

    >span{
      position: relative;

      &:before, &:after{
        content: "";
        background-color: ${PAPER};
        width: 20px;
        height: 1px;
        position: absolute;
        right: 0;
        top: 50%;
      }

      &:after{
        transform: rotate(90deg);
      }
    }

    &[data-open="true"]{
      >span{
        &:after{
          display: none;
        }
      }
      
      >ul{
        display: flex;
      }
    }

    >ul{
      flex-direction: column;
      display: none;

      >li{
        >a{
          color: ${PAPER};
          padding: ${unit(2)} ${unit(3)};
        }
      }
    }
  }

  ${mediaQuery[SIZE.large]} {
    flex-direction: row;
    padding: 0;

    a, span{
      transition: color 0.5s;
      &:hover{
        color: ${Color(WHITE).alpha(0.7).string()};
      }
    }

    li{
      >a, >span{
        padding: 0;
      }

      >span{
        padding-right: 12px;
        &:before, &:after{
          // content: none;
        }

        &:before, &:after{
          
          background-color: ${PAPER};
          width: 2px;
          height: 8px;
          transform-origin: bottom;
          top: 3px;
        }

        &:before{
          transform: rotate(-45deg);
        }

        &:after{
          transform: rotate(45deg);
        }
      }
      
      >ul{
        position: absolute;
        z-index: 2;
        top: 100%;
        right: ${unit(-4)};
        
        background-color: ${DARK_GRAY};
        padding: ${unit(2)} ${unit(3)};
        text-align: center;

        >li{
          >a{
            padding: ${unit(1)} 0;
          }
        }
      }

      &[data-open="true"]{
        >ul{
          display: none;
        }
      }
      
      &[data-over="true"]{
        >ul{
          display: flex;
        }
      }
    }
  }
`

export const StyledButtonContainer = styled.div`
  padding: 0 ${unit(4)} ${unit(4)} ${unit(4)};
  background-color: ${DARK_GRAY};
  display: flex;
  flex-direction: column;

  ${mediaQuery[SIZE.large]} {
    background-color: transparent;
    position: absolute;
    right: ${unit(4)};
    top: calc(50% + 15px);
    transform: translateY(-50%);
    padding: 0;
  }
`

export const StyledLink = styled.a``

