import { Link as InternalLink } from 'gatsby';
import styled from "styled-components";

import { DARK_GRAY, YELLOW } from '../../theme/colors';
import { column, grid, row } from '../../theme/grid';
import { SIZE, maxWidth, mediaQuery, unit } from '../../theme/metrics';
import { typeHeadingLg, typeLabelLg } from '../../theme/typography';


export const Container = styled.div`
	${grid}
	display: block;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: ${maxWidth()};
	margin-left: auto;
	margin-right: auto;
	padding-top: ${unit(31)};
	padding-bottom: ${unit(31)};

	${mediaQuery[SIZE.medium]} {
		padding-top: 0;
		padding-bottom: 0;
		min-height: 100svh;
	}
`

export const Inner = styled.div`
	${row({ medium: true })}
	margin-bottom: ${unit(6)};

	${mediaQuery[SIZE.large]} {
		margin-bottom: ${unit(17.5)};
	}
`

export const Content = styled.div`
	${column({ medium: { span: 8, before: 2, after: 2 } })}
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

export const LinkContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
`;

export const Message = styled.p`
	${typeHeadingLg}
	position: relative;
	text-align: center;
`;

export const LinksWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;

	${mediaQuery[SIZE.medium]} {
		flex-direction: row;
	}
`;
export const LinkLabel = styled.div`
	${typeLabelLg};
	margin-bottom: ${unit(2)};
	text-transform: uppercase;

	${mediaQuery[SIZE.large]} {
		margin-bottom: ${unit(5)};
	}
`;

export const Link = styled(InternalLink)`
	position: relative;
	color: ${DARK_GRAY};
	text-decoration: none;
	transition: color 180ms ease-out;
	transition: opacity 480ms cubic-bezier(0.22, 1, 0.36, 1);

	&:after {
		position: absolute;
		content: '';
		left: 0;
		bottom: -${unit(0.5)};
		width: 100%;
		height: 1px;
		background-color: ${YELLOW};
		transform: scale(0);
		transition: transform 280ms ease-out;
	}

	&:not(:last-child) {
		margin-bottom: ${unit(1.25)};
	}

	${mediaQuery[SIZE.medium]} {
		&:not(:last-child) {
			margin-right: ${unit(3)};
			margin-bottom: 0;
		}
	}

	${mediaQuery[SIZE.large]} {
		&:hover{
			opacity: 0.8;

			&:after {
				transform: scale(1);
			}
		}
	}
`;
