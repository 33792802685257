import { graphql, useStaticQuery } from "gatsby";
import { useLenis } from 'lenis/react';
import React, { useEffect, useState } from 'react';

import find from 'lodash/find';
import get from 'lodash/get';

import { useModalFormDispatch, useModalFormState } from '../../../context/modal-form-context';

import { ThemeProvider } from "styled-components";
import { getTheme } from "../../../theme/theme";
import { ActivedemandForm } from "../activedemand-form";
import {
	CloseIcon,
	StyledBackground,
	StyledContainer,
	StyledContentContainer,
	StyledHeading,
	StyledImage,
	StyledImageContainer,
	StyledModal,
	StyledText,
	StyledToggleButton,
	StyledToggleContainer,
	StyledToggleItem
} from './modal-form.styles';

const FormToggle = ({ forms, selectedIndex, onSelect }) => {
	return (
		<StyledToggleContainer>
			{forms.map(({ heading }, index) => (
				<StyledToggleItem key={index}>
					<StyledToggleButton $selected={index === selectedIndex} onClick={() => onSelect(index)}>{heading}</StyledToggleButton>
				</StyledToggleItem>
			))}

		</StyledToggleContainer>
	)
}

const ModalForm = () => {
	const dispatch = useModalFormDispatch();
	const { id } = useModalFormState();

	const data = useStaticQuery(graphql`
		query ModalFormQuery {
			forms:allActiveDemandForm {
				nodes {
					id
					formId
					fields{
						type
						label
						list
						required
						choices
						dynamic
						key
						value
					}
				}
			}
			modals:allDatoCmsModalForm {
				nodes {
					id
					heading
					text
					image{
						gatsbyImageData(
							layout: CONSTRAINED,
							placeholder: NONE,
							width: 800,
							imgixParams: {fit: "crop", ar:"3:4", crop: "focalpoint"}
							outputPixelDensities: [0.125,0.25,0.5,1,2]
						)
						alt
					}
					forms {
						formId
						heading
						text
						note
						success
						error
					}
				}
			}
		}
	`)

	const forms = get(data, "forms").nodes;
	const modals = get(data, "modals").nodes;

	if (!forms || !modals) return null;

	const [selectedIndex, setSelectedIndex] = useState(0);

	const modal = find(modals, { id });

	const modalForm = modal.forms[selectedIndex];

	const activeDemandForm = find(forms, { formId: modal.forms[selectedIndex].formId });

	const lenis = useLenis();
	useEffect(() => {
		lenis.stop();
		return () => {
			lenis.start();
		}
	}, [])

	return (
		<ThemeProvider theme={getTheme("default")}>
			<StyledContainer>
				<StyledBackground onClick={() => { dispatch({ action: { type: 'close' } }); }} />
				<StyledModal>
					<CloseIcon width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
						dispatch({ action: { type: 'close' } });
					}}>
						<path d="M9.39331 30.2133L30.6065 9.00005" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M9.39331 9L30.6065 30.2132" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
					</CloseIcon>
					<StyledImageContainer>
						<StyledImage image={modal.image.gatsbyImageData} sizes='(min-width: 800px) 800px, 66vw' alt={modal.image.alt || ""} objectFit="cover" objectPosition="50% 50%" />
					</StyledImageContainer>
					<StyledContentContainer data-lenis-prevent>
						<StyledHeading>{modal.heading}</StyledHeading>
						{modal.text && <StyledText>{modal.text}</StyledText>}
						{modal.forms.length > 1 && <FormToggle forms={modal.forms} selectedIndex={selectedIndex} onSelect={(index) => setSelectedIndex(index)} />}
						{activeDemandForm && <ActivedemandForm {...activeDemandForm} {...modalForm} />}
					</StyledContentContainer>

				</StyledModal>
			</StyledContainer>
		</ThemeProvider>
	);
};

export default ModalForm;
